export const PAYSLIP_TEACHER_TABLE = [
    {type: '', label: 'Các khoản thu nhập',key:'',bold:true},
    {type: '1', label: 'Thu nhập dạy học (1)',key:'thu_nhap_day_hoc',isDetail:true},
    {type: '', label: 'Trả cọc (2)',key:'tra_coc'},
    {type: '3', label: 'Thu nhập khác (3)',key:'thu_nhap_khac',isDetail:true},
    {type: '4', label: 'Thưởng (4)',key:'thuong',isDetail:true},
    {type: '', label: 'Tổng các khoản thu nhập (5)',key:'tong_cac_khoan_thu_nhap'},
    {type: '10', label: 'Giá trị trái phiếu tích lũy',key:'thuong_trai_phieu_tich_luy',isDetail:true},
    {type: '', label: '',key:'',last_step:true},
    {type: '', label: 'Các khoản trừ thu nhập',key:'',bold:true},
    {type: '', label: 'Thu cọc (6)',key:'thu_coc'},
    {type: '7', label: 'Phạt (7)',key:'phat',isDetail:true},
    {type: '8', label: 'Trích thuế thu nhập cá nhân (8)',key:'trich_thue_thu_nhap_ca_nhan'},
    {type: '', label: 'Tổng các khoản trừ thu nhập (9)',key:'tong_cac_khoan_thu_nhap'},
    {type: '', label: '',key:'',last_step:true},
    {type: '', label: 'Thu nhập thực nhận (10) = (5) - (9)',key:'thu_nhap_thuc_nhan',bold:true},
];